import "./Home.css";

import Icon from "../Icon";
import Link from "../Link";
import About from "../About";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as HeartIcon } from "./heart.svg";
import { ReactComponent as HelpIcon } from "./help.svg";
import { useUrl, useIsFirstLoad } from "../navigation";
import { isTouchDevice } from "../input";
import { useNetworkOnline } from "../network";
import { isFullscreenSupported } from "../screen";
import { CATEGORY_IDS, getCategoryName } from "../constants";
import type { CategoryId } from "../constants";

type HomeProps = {
  categoryId: CategoryId;
  isGameMode: boolean;
  isFullscreen: boolean;
  onSelectCategory: (categoryId: CategoryId) => void;
  onToggleGameMode: (isGameMode: boolean) => void;
  onToggleFullscreen: (isFullscreen: boolean) => void;
  onStart: () => void;
};

const Home = ({
  categoryId,
  isGameMode,
  isFullscreen,
  onSelectCategory,
  onToggleGameMode,
  onToggleFullscreen,
  onStart,
}: HomeProps) => {
  const isAboutVisible = useUrl().pathname === "/about";
  const isAboutAnimated = !useIsFirstLoad();

  const isOffline = !useNetworkOnline();

  return (
    <div className="home">
      <div className="content">
        <header>
          <h1>
            Pic<span>roll</span>
            <Logo className="logo" />
          </h1>
        </header>

        <Icon
          to="/about"
          className="about-button"
          title="About"
          icon={HelpIcon}
        />
        {isAboutVisible && <About animate={isAboutAnimated} />}

        <main>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (isOffline) {
                window.alert(
                  "Your device is offline. An internet connection is needed to start the game."
                );
                return;
              }

              onStart();
            }}
          >
            <label className="category">
              <span className="info">Category</span>
              <select
                value={categoryId}
                onChange={({ target: { value } }) =>
                  onSelectCategory(value as CategoryId)
                }
              >
                {CATEGORY_IDS.map((categoryId) => (
                  <option key={categoryId} value={categoryId}>
                    {getCategoryName(categoryId)}
                  </option>
                ))}
              </select>
            </label>

            <div className="toggle-section">
              <label>
                <span className="info">
                  Game mode
                  <p>
                    {isGameMode
                      ? `No image descriptions will be shown until you ${
                          isTouchDevice ? "tap" : "click"
                        } to reveal`
                      : "Image descriptions will be shown at all times"}
                  </p>
                </span>
                <input
                  type="checkbox"
                  checked={isGameMode}
                  onChange={({ target: { checked } }) =>
                    onToggleGameMode(checked)
                  }
                />
                <div className="toggle">
                  <span className="on">On</span>
                  <span className="off">Off</span>
                </div>
              </label>
            </div>

            {isFullscreenSupported && (
              <div className="toggle-section">
                <label>
                  <span className="info">
                    Full screen
                    <p>
                      {isFullscreen
                        ? "Game will cover the entire screen once started"
                        : "Game will remain at current size once started"}
                    </p>
                  </span>
                  <input
                    type="checkbox"
                    checked={isFullscreen}
                    onChange={({ target: { checked } }) =>
                      onToggleFullscreen(checked)
                    }
                  />
                  <div className="toggle">
                    <span className="on">On</span>
                    <span className="off">Off</span>
                  </div>
                </label>
              </div>
            )}

            <button
              className={isOffline ? "disabled" : undefined}
              type="submit"
            >
              Start
            </button>
          </form>
        </main>
      </div>

      <footer>
        <HeartIcon className="heart-icon" />
        Made by&nbsp;
        <Link href={process.env.REACT_APP_RAMON_URL!}>Ramón</Link>
        &nbsp;&&nbsp;
        <Link href={process.env.REACT_APP_MITCHI_URL!}>Mitchi</Link>
      </footer>
    </div>
  );
};

export default Home;
