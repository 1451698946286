import { forwardRef } from "react";
import type { AnchorHTMLAttributes, Ref } from "react";

import { navigateTo } from "./navigation";
import type { ValidURL } from "./navigation";

type LinkProps = Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  "className" | "onClick" | "children" | "title"
> &
  ({ to: ValidURL; replace?: boolean } | { href: string });

const Link = forwardRef(
  ({ children, ...rest }: LinkProps, ref?: Ref<HTMLAnchorElement>) => {
    if ("href" in rest) {
      return (
        <a ref={ref} target="_blank" rel="noopener noreferrer" {...rest}>
          {children}
        </a>
      );
    }

    const { to, replace, onClick, ...other } = rest;
    return (
      <a
        ref={ref}
        href={to}
        onClick={(event) => {
          event.preventDefault();
          if (onClick) {
            onClick(event);
          }
          navigateTo(to, { replace });
        }}
        {...other}
      >
        {children}
      </a>
    );
  }
);

export default Link;
