import "./FullscreenLoading.css";

import LoadingSpinner from "./LoadingSpinner";

const FullscreenLoading = () => (
  <div className="fullscreen-loading">
    <LoadingSpinner />
  </div>
);

export default FullscreenLoading;
