import "./index.css";

import { StrictMode } from "react";
import { render } from "react-dom";
import App from "./App";

export const ROOT_ELEMENT = document.getElementById("root")!;

render(
  <StrictMode>
    <App />
  </StrictMode>,
  ROOT_ELEMENT
);
