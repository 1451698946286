import { useState, useEffect } from "react";

const isConnectionDownlinkSupported =
  "connection" in navigator && "downlink" in navigator.connection;
declare global {
  interface Navigator {
    connection: {
      /**
       * Effective bandwidth estimate in Mbps
       */
      downlink: number;
      addEventListener(
        type: "change",
        listener: EventListenerOrEventListenerObject
      ): void;
      removeEventListener(
        type: "change",
        listener: EventListenerOrEventListenerObject
      ): void;
    };
  }
}

const getConnectionDownlink = () =>
  isConnectionDownlinkSupported ? navigator.connection.downlink : undefined;

export const useNetworkMbps = () => {
  const [networkMbps, setNetworkMbps] = useState(getConnectionDownlink);

  useEffect(() => {
    if (!isConnectionDownlinkSupported) return;

    const handler = () => {
      setNetworkMbps(getConnectionDownlink());
    };
    navigator.connection.addEventListener("change", handler);
    return () => {
      navigator.connection.removeEventListener("change", handler);
    };
  }, []);

  return networkMbps;
};

export const useNetworkOnline = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handler = () => setIsOnline(navigator.onLine);
    window.addEventListener("online", handler);
    window.addEventListener("offline", handler);

    return () => {
      window.removeEventListener("online", handler);
      window.removeEventListener("offline", handler);
    };
  }, []);

  return isOnline;
};
