import { useState, useEffect } from "react";

export const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;

export const useKeyPress = (key: string, onKeyPress?: () => void) => {
  useEffect(() => {
    if (!onKeyPress) return;

    const handler = ({ key: pressedKey }: KeyboardEvent) => {
      if (pressedKey === key) {
        onKeyPress();
      }
    };
    window.addEventListener("keydown", handler, { passive: true });
    return () => window.removeEventListener("keydown", handler);
  }, [key, onKeyPress]);
};

export const useUserActivity = (timeout: number) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let timeoutId: number | undefined;

    const handler = () => {
      setIsActive(true);
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => setIsActive(false), timeout);
    };

    window.addEventListener("mousemove", handler, { passive: true });
    return () => {
      window.clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handler);
    };
  }, [timeout]);

  return isActive;
};
