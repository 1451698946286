import { lazy, Suspense, useEffect, useState, useCallback } from "react";

import Home from "./Home";
import FullscreenLoading from "./FullscreenLoading";
import { navigateTo, useUrl, useIsFirstLoad } from "./navigation";
import { useFullscreen } from "./screen";
import { useKeyPress } from "./input";
import { CATEGORY_IDS } from "./constants";

const Player = lazy(
  () =>
    import(/* webpackChunkName: "player", webpackPrefetch: true */ "./Player")
);

const usePlayingState = (
  isFullscreenMode: boolean
): [boolean, (isPlaying: boolean) => void] => {
  const isPlaying = useUrl().pathname === "/play";
  const setFullscreen = useFullscreen(isFullscreenMode, (enable) => {
    if (!enable) {
      setIsPlaying(false);
    }
  });

  const setIsPlaying = useCallback(
    (newIsPlaying: boolean) => {
      navigateTo(newIsPlaying ? "/play" : "/", { replace: !newIsPlaying });
      setFullscreen(newIsPlaying);
    },
    [setFullscreen]
  );

  useEffect(() => {
    if (!isPlaying) {
      setFullscreen(false);
    }
  }, [isPlaying, setFullscreen]);

  const isFirstLoad = useIsFirstLoad();
  useEffect(() => {
    if (isFirstLoad && isPlaying) {
      setIsPlaying(false);
    }
  }, [isFirstLoad, isPlaying, setIsPlaying]);

  useKeyPress("Escape", () => {
    setIsPlaying(false);
  });

  return [isPlaying, setIsPlaying];
};

const App = () => {
  const [categoryId, setCategoryId] = useState(CATEGORY_IDS[0]);
  const [isGameMode, setIsGameMode] = useState(true);
  const [isFullscreenMode, setIsFullscreenMode] = useState(true);

  const [isPlaying, setIsPlaying] = usePlayingState(isFullscreenMode);

  if (!isPlaying) {
    return (
      <Home
        categoryId={categoryId}
        isGameMode={isGameMode}
        isFullscreen={isFullscreenMode}
        onSelectCategory={setCategoryId}
        onToggleGameMode={setIsGameMode}
        onToggleFullscreen={setIsFullscreenMode}
        onStart={() => setIsPlaying(true)}
      />
    );
  }

  return (
    <Suspense fallback={<FullscreenLoading />}>
      <Player
        categoryId={categoryId}
        isGameMode={isGameMode}
        onExit={() => setIsPlaying(false)}
      />
    </Suspense>
  );
};

export default App;
