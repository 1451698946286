import "./Icon.css";

import { forwardRef } from "react";
import type { Ref, ComponentProps, ComponentType, SVGProps } from "react";

import Link from "./Link";

type IconProps = {
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  title: string;
  className?: string;
  autoScale?: boolean;
} & (ComponentProps<typeof Link> | {});

const Icon = forwardRef(
  (
    { icon: IconComponent, className = "", autoScale, ...rest }: IconProps,
    ref?: Ref<HTMLDivElement>
  ) => {
    const isInteractive = "href" in rest || "to" in rest || "onClick" in rest;
    const commonProps = {
      ref,
      className: `icon-wrapper ${
        isInteractive ? "interactive" : ""
      } ${className}`,
      children: (
        <IconComponent className={`icon ${autoScale ? "auto-scale" : ""}`} />
      ),
    };

    return "href" in rest || "to" in rest ? (
      <Link {...commonProps} {...rest} />
    ) : (
      <div {...commonProps} {...rest} />
    );
  }
);

export default Icon;
