import { useState, useRef, useEffect } from "react";
import history from "history/browser";

const VALID_URLS = ["/", "/about", "/play"] as const;
export type ValidURL = typeof VALID_URLS[number];

export const useIsFirstLoad = () => {
  const isFirstLoadRef = useRef(true);
  useEffect(() => {
    isFirstLoadRef.current = false;
  });
  return isFirstLoadRef.current;
};

export const navigateTo = (
  to: ValidURL,
  { replace }: { replace?: boolean } = {}
) => {
  replace ? history.replace(to) : history.push(to);
};

export const useUrl = () => {
  const [url, setUrl] = useState(history.location);
  const { pathname } = url;

  useEffect(() => {
    if (!(VALID_URLS as readonly string[]).includes(pathname)) {
      navigateTo("/", { replace: true });
    }
  }, [pathname]);

  useEffect(() => {
    const unlisten = history.listen(({ location }) => setUrl(location));
    return unlisten;
  }, []);

  return url;
};
