import "./About.css";

import { useRef } from "react";

import Modal from "./Modal";
import Icon from "../Icon";
import Link from "../Link";
import { ReactComponent as CloseIcon } from "./close.svg";
import { navigateTo } from "../navigation";
import { isTouchDevice } from "../input";

import ramonGuijarroImgSrc from "./ramon-guijarro.jpg";
import mitchiWalkerImgSrc from "./mitchi-walker.jpg";

const TITLE_ID = "title";

type AboutProps = { animate: boolean };

const About = ({ animate }: AboutProps) => {
  const closeRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      titleId={TITLE_ID}
      focusRef={closeRef}
      className="about"
      onClose={() => navigateTo("/", { replace: true })}
      animate={animate}
    >
      <header>
        <h1 id={TITLE_ID}>About Picroll</h1>
        <Icon
          className="close"
          to="/"
          replace
          title="Close"
          icon={CloseIcon}
          ref={closeRef}
        />
      </header>

      <main>
        <div className="authors">
          <div className="photos">
            <img
              width={78}
              height={78}
              src={ramonGuijarroImgSrc}
              alt="Ramón Guijarro"
            />
            <img
              width={78}
              height={78}
              src={mitchiWalkerImgSrc}
              alt="Mitchi Walker"
            />
          </div>
          <p>
            This app was created by{" "}
            <Link href={process.env.REACT_APP_RAMON_URL!}>Ramón Guijarro</Link>{" "}
            and{" "}
            <Link href={process.env.REACT_APP_MITCHI_URL!}>Mitchi Walker</Link>{" "}
            from sunny Madrid, Spain.
          </p>
        </div>
        <p>
          It was inspired by the default slideshow of images displayed on our{" "}
          <Link href="https://store.google.com/product/chromecast">
            Google Chromecast
          </Link>
          . We had a lot of fun trying to guess where the images were taken, but
          we had to manually search for each of them to figure out if we were
          correct. The idea to create a simple game that could be played on any
          device was then born.
        </p>
        <h2>How to play</h2>
        <p>
          Select a category and press the Start button. You'll see a slideshow
          of images.
        </p>
        <p>
          Now try to guess where they were taken. Whenever you're ready,{" "}
          {isTouchDevice ? "tap" : "click"} anywhere to reveal the answer. Then{" "}
          {isTouchDevice ? "tap" : "click"} again to move to the next set.{" "}
          {!isTouchDevice && "You can alternatively use the space bar."}
        </p>
        <p>
          To end the game, {isTouchDevice ? "" : "press the Escape key or "}
          {isTouchDevice ? "tap" : "click"} the cog icon in the top right corner
          of the screen.
        </p>
        <h2>Credits</h2>
        <p>
          The app was written by Ramón. Source code is available at the{" "}
          <Link href="https://github.com/soyguijarro/picroll">
            GitHub repository
          </Link>
          .
        </p>
        <p>
          All photos were selected by Mitchi. They are used with permission,
          most of them via <Link href="https://unsplash.com/">Unsplash</Link>.
        </p>
      </main>
    </Modal>
  );
};

export default About;
