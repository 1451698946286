import "./Modal.css";

import { useEffect } from "react";
import ReactDOM from "react-dom";
import type { RefObject } from "react";

import { ROOT_ELEMENT } from "..";

const getFocusableElements = (parent: HTMLElement) =>
  parent.querySelectorAll<HTMLElement>(
    'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled])'
  );

const setMainContentInert = (inert: boolean) => {
  ROOT_ELEMENT.style.overflow = inert ? "hidden" : "auto";
  getFocusableElements(ROOT_ELEMENT).forEach((element) => {
    element.tabIndex = inert ? -1 : 0;
  });
};

type ModalProps = {
  titleId: string;
  focusRef: RefObject<HTMLDivElement>;
  onClose: () => void;
  children: React.ReactNode;
  animate?: boolean;
  className?: string;
};

const Modal = ({
  titleId,
  focusRef,
  onClose,
  children,
  animate = true,
  className = "",
}: ModalProps) => {
  useEffect(() => {
    const focusedElement = document.activeElement as HTMLElement | null;
    focusRef.current?.focus();
    return () => focusedElement?.focus();
  }, [focusRef]);

  useEffect(() => {
    setMainContentInert(true);
    return () => setMainContentInert(false);
  }, []);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div
      className={`modal ${animate ? "animated" : ""}`}
      onClick={onClose}
      onKeyDown={onKeyDown}
      role="dialog"
      aria-modal="true"
      aria-labelledby={titleId}
    >
      <div
        className={`content ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
