import CATEGORIES from "./categories.json";

export type CategoryId = keyof typeof CATEGORIES;
export const CATEGORY_IDS = Object.keys(CATEGORIES) as CategoryId[];

const getCategory = (categoryId: CategoryId) => CATEGORIES[categoryId];

export const getCategoryName = (categoryId: CategoryId) =>
  getCategory(categoryId).name;

const shuffle = <T>(array: T[]) => {
  const a = array.slice();
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export const getCategoryItems = (categoryId: CategoryId) => {
  const items = getCategory(categoryId).items.map(
    ({ id, title, subtitle, images }) => ({
      id,
      title,
      subtitle,
      urls: images.map(
        (image) => `/images/categories/${categoryId}/${id}/${image}`
      ),
    })
  );
  return shuffle(items.map((item) => ({ ...item, urls: shuffle(item.urls) })));
};

export const CAROUSEL_DELAY = 8 * 1000;
export const CURSOR_HIDE_DELAY = 2 * 1000;
