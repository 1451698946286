import { useEffect, useRef } from "react";
import fscreen from "fscreen";

export const isFullscreenSupported =
  fscreen.fullscreenEnabled &&
  process.env.REACT_APP_FULLSCREEN_ENABLED !== "false";
const getIsFullscreen = () =>
  isFullscreenSupported && !!fscreen.fullscreenElement;

export const useFullscreen = (
  isEnabledParam: boolean,
  onFullscreenChange: (enable: boolean) => void
) => {
  const isEnabled = isFullscreenSupported && isEnabledParam;

  useEffect(() => {
    if (!isEnabled) return;

    const handler = () => onFullscreenChange(getIsFullscreen());
    fscreen.addEventListener("fullscreenchange", handler, { passive: true });
    return () => fscreen.removeEventListener("fullscreenchange", handler);
  }, [isEnabled, onFullscreenChange]);

  const setFullscreen = (enable: boolean) => {
    if (!isEnabled) return;

    const isFullscreen = getIsFullscreen();
    if (enable && !isFullscreen) {
      fscreen.requestFullscreen(document.body);
    } else if (!enable && isFullscreen) {
      fscreen.exitFullscreen();
    }
  };
  return setFullscreen;
};

export const useScreenOrientationLock = (
  orientation: "portrait" | "landscape"
) => {
  useEffect(() => {
    (async () => {
      try {
        await window.screen.orientation.lock(orientation);
      } catch {}
    })();
    return () => {
      try {
        window.screen.orientation.unlock();
      } catch {}
    };
  }, [orientation]);
};

const isWakeLockSupported = "wakeLock" in navigator;
interface WakeLockSentinel {
  release(): Promise<void>;
}
declare global {
  interface Navigator {
    wakeLock: {
      request: (type?: "screen") => Promise<WakeLockSentinel>;
    };
  }
}

export const useScreenWakeLock = () => {
  const wakeLockRef = useRef<WakeLockSentinel>();
  const requestWakeLock = async () => {
    try {
      wakeLockRef.current = await navigator.wakeLock.request();
    } catch {}
  };
  const releaseWakeLock = async () => {
    try {
      await wakeLockRef.current?.release();
    } catch {}
  };

  useEffect(() => {
    if (!isWakeLockSupported) {
      return;
    }

    requestWakeLock();
    return () => {
      releaseWakeLock();
    };
  }, []);

  useEffect(() => {
    if (!isWakeLockSupported) {
      return;
    }

    const handler = async () => {
      if (document.visibilityState === "visible") {
        requestWakeLock();
      }
    };
    document.addEventListener("visibilitychange", handler);
    return () => document.removeEventListener("visibilitychange", handler);
  }, []);
};
